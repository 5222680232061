import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import "./components.css";

export const PreLoader: FC = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
     <div className="loading-text"> Loading from onchain, N/W Congestion might affect the page loading ...</div>
    </div>
  );
};
